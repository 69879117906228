import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

function Layout({ children }) {
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <div className="flex flex-col min-h-screen antialiased text-grey-darkest">
                    <div className="flex flex-col flex-1 w-full overflow-hidden lg:overflow-visible">
                        {children}
                    </div>
                </div>
            )}
        />
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
