import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import abductionIllustration from '../images/404/abduction-illustration.svg';

function NotFoundPage() {
    return (
        <Layout>
            <SEO title="404: Not found" />
            <div>
                <img
                    src={abductionIllustration}
                    className="h-112 w-1/2 mx-auto my-32"
                    alt="Developer getting abducted by aliens."
                />
            </div>
        </Layout>
    );
}

export default NotFoundPage;
